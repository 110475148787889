'use strict'

import type { ImageObject } from 'schema-dts'
import type { IDataMain, IPageData } from '../../../types/page'

import Head from 'next/head'
import config from '../../../config'
import { picturesToImageObject } from '../../../helpers/seoFormat'
import { formatAbsoluteUrl } from '../../../helpers/url'

const itemsBlocksTypes = [
  'article-list',
  'carousel-highlights',
  'category-header',
  'program-list',
  'program-video-list',
  'topic-header',
  'video-list',
] as const

export function generateItemList(
  modules: IPageData['data'],
): { position: number; url: string; name: string; image: ImageObject }[] {
  const mainSection = modules?.find((elem) => elem.key === 'main') as IDataMain
  const bodySection = mainSection?.data?.find((elem) => elem.key === 'body')
  const bodyHeaderSection = mainSection?.data?.find((elem) => elem.key === 'body-header')

  const blockList = [...(bodyHeaderSection?.data || []), ...(bodySection?.data || [])]

  const itemList = blockList
    .filter((block) => {
      const key = block.key as typeof itemsBlocksTypes[number]
      return itemsBlocksTypes.includes(key)
    })
    .flatMap((block) => {
      switch (block.key) {
        case 'article-list':
        case 'carousel-highlights':
        case 'category-header':
        case 'program-list':
        case 'program-video-list':
        case 'topic-header':
        case 'video-list':
          return block.data.elementList.map((element) => ({
            url: element.link,
            name: element.title,
            image: picturesToImageObject(element.pictures),
          }))
        default:
      }
    })

  const itemLists = itemList.map(({ url, name, image }, index) => ({
    position: index + 1,
    url,
    name,
    image,
  }))

  return itemLists
}

export function SEOItemLists({
  title,
  url,
  modules,
}: {
  title: string
  url: string
  modules: IPageData['data']
}): JSX.Element {
  const list = generateItemList(modules)

  if (!list.length) return null

  const items = [
    {
      '@context': 'http://schema.org',
      '@type': 'ItemList',
      name: title,
      url: formatAbsoluteUrl(url),
      numberOfItems: list.length,
      itemListOrder: 'http://schema.org/ItemListOrderDescending',
      itemListElement:
        list?.map(({ position, url, name, image }) => ({
          '@type': 'ListItem',
          position,
          url: formatAbsoluteUrl(url),
          name,
          image,
        })) || [],
    },
  ]

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(items, null, config.environment === 'production' ? null : 4),
        }}
      />
    </Head>
  )
}
